import View from './View.js';

class HeroView extends View {
  _parentElementId = document.querySelector('.connections-container');
  _container = '.graph-container';
  _containerEl = document.querySelector('.graph-container');
  offsetWidth = 2; // modify the centering of the svg
  width = this._parentElementId.offsetWidth / this.offsetWidth;
  height = this._parentElementId.offsetHeight;
}

export default new HeroView();
