import * as d3 from 'd3';
import {
  linkColor,
  linkWidth,
  nodesCirclesColor,
  nodesCirclesRadius,
  nodeDistance,
  labelDistance,
} from '../config.js';

export default class View {
  _parentElementId;
  _container;
  _containerEl;
  width;
  height;
  offsetWidth; // modify the centering of the svg
  _data;
  _svg;
  _simulation;
  _graphGroup;
  _links;
  _nodes;
  tooltip;
  isTooltipHovered;
  currentNode;
  tooltipRemovalTimeout;

  renderView(data) {
    this.renderSpinner();
    this._data = data;
    this.clearHTML(this._containerEl);
    this.createSVG();
    this.renderSimulation();
    this.createGraphGroup();
    this.renderLinks();
    this.renderNodes();
    this.addHandlerZoom();
    this.addHandlerDrag();
    this.createTooltipElement();
    this.addHandlerNodesEnterEvents();
    this.addHandlerNodesLeaveEvents();
    this.addHandlerHoverTooltip();
    this.addHandlerReloadSVG();
  }

  //HELPERS METHODS

  toggleHidden(element) {
    element.classList.toggle('hidden');
  }

  clearHTML(element) {
    element.innerHTML = '';
  }

  capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  calculateContainerWidth() {
    return (this.width = this._parentElementId.offsetWidth / this.offsetWidth);
  }
  calculateContainerHeight() {
    return (this.height = this._parentElementId.offsetHeight);
  }

  reRenderView(classInstance, getClonedData) {
    const newData = getClonedData();
    classInstance.clearHTML(classInstance._containerEl);
    classInstance.renderView(newData);
  }

  renderSpinner() {
    this._containerEl.insertAdjacentHTML(
      'afterbegin',
      `<div class="spinner">
    <svg>
    <symbol id="icon-loader" viewBox="0 0 24 24">
    <path d="M11 2v4c0 0.552 0.448 1 1 1s1-0.448 1-1v-4c0-0.552-0.448-1-1-1s-1 0.448-1 1zM11 18v4c0 0.552 0.448 1 1 1s1-0.448 1-1v-4c0-0.552-0.448-1-1-1s-1 0.448-1 1zM4.223 5.637l2.83 2.83c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-2.83-2.83c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414zM15.533 16.947l2.83 2.83c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-2.83-2.83c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414zM2 13h4c0.552 0 1-0.448 1-1s-0.448-1-1-1h-4c-0.552 0-1 0.448-1 1s0.448 1 1 1zM18 13h4c0.552 0 1-0.448 1-1s-0.448-1-1-1h-4c-0.552 0-1 0.448-1 1s0.448 1 1 1zM5.637 19.777l2.83-2.83c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-2.83 2.83c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0zM16.947 8.467l2.83-2.83c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-2.83 2.83c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0z"></path>
    </symbol>
    </svg>
  </div>`
    );
  }

  //GRAPH

  createSVG() {
    console.log(this.width);
    console.log(this.height);
    return (this._svg = d3
      .select(this._container)
      .append('svg')
      .classed('graph-svg', true) // .graph-svg not defined !!!
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('viewport', [0, 0, this.width, this.height])) // Create SVG
      .style('border', 'solid 1px #f0f')
      .style('overflow', 'hidden');
  }

  renderSimulation() {
    return (this._simulation = d3
      .forceSimulation(this._data.nodes)
      .force(
        'link',
        d3
          .forceLink(this._data.links)
          .id(d => d.id)
          .distance(nodeDistance)
      )
      .force('charge', d3.forceManyBody().distanceMax(100))
      .force('center', d3.forceCenter(this.width / 2, this.height / 2))
      .on('tick', () => this.ticked()));
  }

  ticked() {
    // Update link positions
    this._links
      .attr('x1', d => d.source.x)
      .attr('x2', d => d.target.x)
      .attr('y1', d => d.source.y)
      .attr('y2', d => d.target.y);

    // Update node positions - this only updates visualization, not the datum (data)
    this._nodes.attr('transform', d => `translate(${d.x}, ${d.y})`);
  }

  createGraphGroup() {
    return (this._graphGroup = this._svg.append('g'));
  }

  renderLinks() {
    return (this._links = this._graphGroup
      .selectAll() // you need to use the name of an element, you cannot use the name that you want. or use a class
      .data(this._data.links)
      .join('line')
      .attr('stroke', linkColor)).attr('stroke-width', linkWidth);
  }

  renderNodes() {
    this.createNodeGroups();
    this.createNodesCircles();
    this.createNodesLabels();
  }

  createNodeGroups() {
    return (this._nodes = this._graphGroup
      .attr('class', 'nodes')
      .selectAll('g')
      .data(this._data.nodes)
      .enter() // enter the nodes from the array?
      .append('g')); // create 1 group per node (d)
  }

  createNodesCircles() {
    this._nodes
      .append('circle')
      .attr('r', nodesCirclesRadius)
      .attr('fill', nodesCirclesColor)
      .classed('select-element', true);
  }

  createNodesLabels() {
    this._nodes
      .append('text')
      .text(d => d.id)
      .attr('x', 0) // Center the text relative to the group
      .attr('y', labelDistance) // Position the text below the circle relative to the group
      .attr('text-anchor', 'middle') // Ensure the text is centered horizontally relative to itself
      .attr('alignment-baseline', 'middle') // Ensure the text is centered vertically relative to itself
      .classed('cursor', true);
  }

  //ZOOM METHODS

  addHandlerZoom() {
    const zoom = d3
      .zoom()
      .scaleExtent([0.3, 5])
      .on('zoom', event => this.activateZoom(event.transform)); // zoom does not return the datum

    this._svg.call(zoom); // zoom is called on the svg
  }

  activateZoom(transform) {
    this._svg.selectAll('.nodes').attr('transform', transform);

    // Animate the graph when zooming in (zooming works anyway)
    this.activateLinkScaling(transform);
  }

  activateLinkScaling(transform) {
    this._simulation
      .alphaTarget(0.01)
      .restart()
      .force(
        'link',
        d3
          .forceLink(this._data.links)
          .id(d => d.id)
          .distance(nodeDistance * transform.k)
      )
      .on('tick', () => this.ticked());
  }

  //DRAGGING

  addHandlerDrag() {
    this._nodes.call(
      d3
        .drag()
        .on('start', event => this.activateStartDragging(event))
        .on('drag', event => this.activateDragging(event))
        .on('end', event => this.activateEndDragging(event))
    );
  }

  activateStartDragging(event) {
    if (!event.active) {
      this._simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }
  }
  activateDragging(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;

    // Remove tooltip while dragging
    this.removeTooltip();
  }
  activateEndDragging(event) {
    if (!event.active) {
      this._simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }
  }

  addHandlerNodesEnterEvents() {
    this._nodes.selectAll('circle').on('mouseenter', (event, d) => {
      this.renderNodesHighlight(event, d);
      this.addHandlerRenderTooltip(event, d);
      if (this.currentNode === d) {
        clearTimeout(this.tooltipRemovalTimeout); // timeout for tooltip is cleared only if you are entering the same node
      } else {
        this.currentNode = d;
      }
    });
  }

  //HIGHLIGHT NODES

  renderNodesHighlight(event, d) {
    // Reduce the opacity of all nodes and links
    this.applyOverlayToAll();
    // Apply highlighted style to target node
    this.toggleHighlightTargetNode(event);
    // Apply highlight style to the connected elements
    this.highlightConnections(d);
  }

  applyOverlayToAll() {
    this._nodes.classed('overlayed', true);
    this._links.classed('overlayed', true);
  }

  // Takes in the event from the event handler
  toggleHighlightTargetNode(event) {
    // Identify the parent element with d3
    let parentNodeElement = d3.select(event.currentTarget.parentNode); // selects the target node group (node (circle) and label)

    parentNodeElement.classed('overlayed', false);

    // Toggle highlighted style for circles

    if (event.type === 'mouseenter') {
      parentNodeElement.select('circle').classed('highlighted-node', true);
      parentNodeElement
        .select('text')
        .transition()
        .duration(200)
        .attr('y', labelDistance + 5);
    }

    if (event.type === 'mouseleave') {
      parentNodeElement.select('circle').classed('highlighted-node', false);
      parentNodeElement
        .transition()
        .duration(200)
        .select('text')
        .attr('y', labelDistance);
    }
  }

  // Takes in the d (datum) from the event handler. the d is target node in the form of object (as in state)
  highlightConnections(d) {
    const connectedLinks = this.getConnectedLinks(d);
    const connectedNodeIds = this.getConnectedNodes(connectedLinks);

    this._links
      .classed('highlighted-link', d => connectedLinks.includes(d))
      .classed('overlayed', false);

    this._nodes.each(function (nodeData) {
      if (nodeData.id === d.id) {
        return;
      } else if (connectedNodeIds.has(nodeData.id)) {
        d3.select(this).classed('highlighted-ConnectedNode', true);
      }
    });
  }

  getConnectedLinks(d) {
    return this._data.links.filter(
      link => link.source.id === d.id || link.target.id === d.id
    ); // Array of connected links [{source, target}]
  }

  getConnectedNodes(connectedLinks) {
    return new Set(
      connectedLinks.flatMap(link => [link.source.id, link.target.id])
    ); // Set of ids of connected nodes [id]
  }

  addHandlerNodesLeaveEvents() {
    this._nodes.selectAll('circle').on('mouseleave', event => {
      this.removeNodesHighlight(event);
      this.scheduleTooltipRemoval();
    });
  }

  removeNodesHighlight(event) {
    this._nodes.classed('overlayed', false);
    this._nodes.classed('highlighted-node ', false);
    this._nodes.classed('highlighted-ConnectedNode ', false);
    this._links.classed('overlayed', false);
    this._links.classed('highlighted-link', false);

    this.toggleHighlightTargetNode(event);
  }

  addHandlerReloadSVG() {
    window.addEventListener('resize', () => {
      this.calculateContainerHeight();
      this.calculateContainerWidth();
      this._svg.attr('width', this.width).attr('height', this.height);
    });
  }

  //TOOLTIP

  createTooltipElement() {
    this.tooltip = d3
      .select(this._container)
      .append('div')
      .attr('class', 'tooltip')
      .classed('hidden', true) // it has to be classed, not attr, or the last attr will overwrite the first
      .style('opacity', 0);

    return this.tooltip;
  }

  addHandlerRenderTooltip(event, d) {
    const targetNodeObject = this.identifyTargetNode(event);

    // Guard clause to avoid creating empty tooltip
    if (targetNodeObject.url.length === 0) return;

    // Apply current zoom transform to node positions
    const currentTransform = d3.zoomTransform(this._svg.node()); // retrieves the current zoom transform state of the nodes (transform object)
    const transformedX = currentTransform.applyX(d.x); // returns new coordinates for the nodes
    const transformedY = currentTransform.applyY(d.y); // returns new coordinates for the nodes

    // Remove hidden class
    this.tooltip.classed('hidden', false);

    this.tooltip
      .html(this.generateTooltipMarkup(targetNodeObject))
      .style('position', 'absolute')
      .style('left', transformedX + 7 + 'px') // change to rem???
      .style('top', transformedY + 'px') // change to rem???
      .style('background-color', '#fff')
      .style('border', '1px solid #ccc')
      .style('border-radius', '5px')
      .style('padding', '5px 5px');

    this.tooltip.transition().duration(200).style('opacity', 0.9);
  }

  addHandlerHoverTooltip() {
    this.tooltip
      .on('mouseenter', () => {
        clearTimeout(this.tooltipRemovalTimeout);
        this.isTooltipHovered = true; // not sure this logic works ???
        this.tooltip.style('opacity', 0.9);
      })
      .on('mouseleave', () => {
        this.isTooltipHovered = false; // not sure this logic works ???
        this.scheduleTooltipRemoval();
      });
  }

  scheduleTooltipRemoval() {
    clearTimeout(this.tooltipRemovalTimeout);

    // setTimeout serves to delay the hiding of the tooltip so the user has time to hover over it (debounce strategy)
    // tooltipRemovalTimeout is an id returned by setTimeout, which can be used to clear the timer
    this.tooltipRemovalTimeout = setTimeout(() => {
      if (!this.isTooltipHovered) {
        this.removeTooltip();
      }
    }, 300);
  }

  removeTooltip() {
    this.tooltip.classed('hidden', true);
    this.tooltip.transition().duration(200).style('opacity', 0);
  }

  renderTooltipMarkup(event) {
    return this.generateTooltipMarkup(this.identifyTargetNode(event));
  }

  // Maybe this can be avoided using the datum from d3 ???
  identifyTargetNode(event) {
    if (!event.target || !event.target.parentNode) return;
    //  DOM traversing - selecting the right element in the DOM
    const targetNodeParentEl = event.target.parentNode;

    // Identifying the text of the label and its guard clause
    const textLabel = targetNodeParentEl.querySelector('text');
    if (!textLabel) return;
    const nodeLabel = textLabel.textContent;

    //  Identifying target object between nodes
    const targetNodeObject = this._data.nodes.find(
      object => object.id === nodeLabel
    );
    if (!targetNodeObject) return;

    return targetNodeObject;
  }

  generateTooltipMarkup(targetNodeObject) {
    return targetNodeObject.url
      .map(url => {
        const startIndex = url.lastIndexOf('/') + 1;
        const linkTitle = decodeURIComponent(url.slice(startIndex)).split('-'); // Array
        let care, number, titleArr;
        [care, number, ...titleArr] = linkTitle; // titleArr is an array with remaining words of the title e.g. [foot, to, mouth]
        care = this.capitalize(care);
        const title = titleArr.map(this.capitalize).join(' '); // join creates a string
        return `<a class = "tooltip-text" href="${url}">${care} #${number}: ${title}</a>`;
      })
      .join(''); // '' allows to avoid commas
  }
}
