import * as model from './model.js';
import modalView from './Views/modalView.js';
import heroView from './Views/heroView.js';

// Function to clone the data, added here because model is automatically generated
const cloneData = function (data) {
  return JSON.parse(JSON.stringify(data));
};

const init = function () {
  console.log(model.data);
  heroView.renderView(cloneData(model.data));
  modalView.addHandlerRenderModal(cloneData(model.data));
  modalView.addHandlerCloseModal(() => cloneData(model.data));
  modalView.addHandlerRecenterGraph(() => cloneData(model.data));
};
init();
