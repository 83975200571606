export const data = {
  "nodes": [
    {
      "id": "brain",
      "url": [
        "https://wellawarecare.substack.com/p/care-1-foot-to-mouth",
        "https://wellawarecare.substack.com/p/care-2-fasting-out-cancer"
      ]
    },
    {
      "id": "foot",
      "url": [
        "https://wellawarecare.substack.com/p/care-1-foot-to-mouth"
      ]
    },
    {
      "id": "heart",
      "url": [
        "https://wellawarecare.substack.com/p/care-1-foot-to-mouth"
      ]
    },
    {
      "id": "stomach",
      "url": [
        "https://wellawarecare.substack.com/p/care-2-fasting-out-cancer",
        "http://example-substack.com/article3"
      ]
    },
    {
      "id": "mouth",
      "url": []
    },
    {
      "id": "lung",
      "url": [
        "http://example-substack.com/article3"
      ]
    },
    {
      "id": "eye",
      "url": []
    },
    {
      "id": "liver",
      "url": []
    },
    {
      "id": "adrenaline",
      "url": []
    },
    {
      "id": "insulin",
      "url": []
    },
    {
      "id": "pancreas",
      "url": []
    },
    {
      "id": "kidney",
      "url": []
    },
    {
      "id": "test3",
      "url": []
    },
    {
      "id": "test4",
      "url": []
    },
    {
      "id": "hand",
      "url": []
    },
    {
      "id": "ear",
      "url": []
    },
    {
      "id": "thyroid",
      "url": [
        "https://wellawarecare.substack.com/p/care-2-fasting-out-cancer"
      ]
    },
    {
      "id": "knee",
      "url": [
        "https://wellawarecare.substack.com/p/care-1-foot-to-mouth"
      ]
    },
    {
      "id": "adrenalgland",
      "url": []
    },
    {
      "id": "tongue",
      "url": []
    },
    {
      "id": "bladder",
      "url": []
    },
    {
      "id": "skin",
      "url": []
    },
    {
      "id": "spleen",
      "url": []
    },
    {
      "id": "nose",
      "url": []
    },
    {
      "id": "gallbladder",
      "url": []
    },
    {
      "id": "finger",
      "url": []
    },
    {
      "id": "esophagus",
      "url": []
    },
    {
      "id": "toe",
      "url": []
    },
    {
      "id": "muscle",
      "url": []
    },
    {
      "id": "spine",
      "url": []
    },
    {
      "id": "bone",
      "url": []
    },
    {
      "id": "nerve",
      "url": []
    },
    {
      "id": "intestine",
      "url": [
        "https://wellawarecare.substack.com/p/care-2-fasting-out-cancer"
      ]
    },
    {
      "id": "shoulder",
      "url": []
    }
  ],
  "links": [
    {
      "source": "brain",
      "target": "foot"
    },
    {
      "source": "heart",
      "target": "foot"
    },
    {
      "source": "stomach",
      "target": "foot"
    },
    {
      "source": "brain",
      "target": "mouth"
    },
    {
      "source": "brain",
      "target": "lung"
    },
    {
      "source": "brain",
      "target": "eye"
    },
    {
      "source": "brain",
      "target": "liver"
    },
    {
      "source": "brain",
      "target": "adrenaline"
    },
    {
      "source": "brain",
      "target": "insulin"
    },
    {
      "source": "brain",
      "target": "pancreas"
    },
    {
      "source": "insulin",
      "target": "kidney"
    },
    {
      "source": "test3",
      "target": "test4"
    },
    {
      "source": "kidney",
      "target": "eye"
    },
    {
      "source": "liver",
      "target": "hand"
    },
    {
      "source": "lung",
      "target": "ear"
    },
    {
      "source": "pancreas",
      "target": "hand"
    },
    {
      "source": "thyroid",
      "target": "knee"
    },
    {
      "source": "adrenalgland",
      "target": "tongue"
    },
    {
      "source": "bladder",
      "target": "skin"
    },
    {
      "source": "spleen",
      "target": "nose"
    },
    {
      "source": "gallbladder",
      "target": "finger"
    },
    {
      "source": "esophagus",
      "target": "toe"
    },
    {
      "source": "muscle",
      "target": "spine"
    },
    {
      "source": "bone",
      "target": "heart"
    },
    {
      "source": "nerve",
      "target": "stomach"
    },
    {
      "source": "intestine",
      "target": "shoulder"
    },
    {
      "source": "skin",
      "target": "brain"
    },
    {
      "source": "ear",
      "target": "pancreas"
    },
    {
      "source": "nose",
      "target": "kidney"
    },
    {
      "source": "tongue",
      "target": "liver"
    },
    {
      "source": "heart",
      "target": "eye"
    },
    {
      "source": "spine",
      "target": "lung"
    }
  ]
}